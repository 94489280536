import React, { useState, useEffect } from 'react';
import calculateStatistics from './APIs/calculateStatistics';
import UserFilters from './Components/UserFilters';
import UserLifts from './Components/UserLifts';
import StatsCharts from './Components/StatsCharts';
import GaugeComponent from './Components/GaugeComponent';
import BackgroundBarbellImage from '../public/images/background3.png';
import styled, { keyframes } from 'styled-components';

const BackgroundContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${BackgroundBarbellImage});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const SubmitButton = styled.button`

  background-color: #345E93;
  font-family: 'Bebas Neue', sans-serif;
  border: none;
  color: white;
  font-size: 30px;
  text-align: center;
  cursor: pointer;
  border-radius: 12px;
  padding: 10px 24px;
  margin-top: 480px;
`;


const StatisticsPage = () => {
    const [chartData, setChartData] = useState({
        Best3SquatKg: 0,
        Best3BenchKg: 0,
        Best3DeadliftKg: 0,
        TotalKg: 0
    });
    const [filters, setFilters] = useState({
        Sex: '',
        Equipment: '',
        AgeClass: '',
        Event: '',
        WeightGroupingKg: ''
    });

    const [lifts, setLifts] = useState({
        Best3SquatKg: 0,
        Best3BenchKg: 0,
        Best3DeadliftKg: 0,
        TotalKg: 0
    });

    const handleSubmit = async () => {
        try {
            const data = await calculateStatistics(filters, lifts);
            console.log('Received data from server:', data);
            console.log('logging percentiles', data.percentiles);
            setChartData(data.percentiles); // Assuming your response has a 'percentiles' key containing chart data
        } catch (error) {
            console.error('Error:', error);
            // Handle the error
        }
    };

    const handleFilterChange = (key, value) => {
        setFilters({
            ...filters,
            [key]: value
        });
    };

    const handleLiftChange = (key, value) => {
        setLifts({
            ...lifts,
            [key]: parseFloat(value)
        });
    };

    return (
        <BackgroundContainer>
        <div className="container mx-auto mt-8">
            <h1 className="text-2xl font-bold text-white">Input Your Information</h1>
            <div className="grid grid-cols-3 gap-8">
                <div className='col-span-1'>
                    <div className="grid grid-cols-1">
                        <div>
                            <UserFilters onChange={handleFilterChange} />
                        </div>
                        <h1 className="text-2xl font-bold mt-10 text-white">Max Lift Input (Kg)</h1>
                        <div>
                            <UserLifts onChange={handleLiftChange} lifts={lifts} setLifts={setLifts} />
                        </div>
                        <button onClick={handleSubmit} className="bg-blue-900 text-white px-4 py-2 rounded-md mt-4">Submit</button>
                    </div>
                </div>
                <div className='col-span-2'>
                <h1 className="text-2xl font-bold text-center text-white">Statistics</h1>
                    <GaugeComponent chartData={chartData} />
                </div>
            </div>
        </div>
        </BackgroundContainer>
    );
}

export default StatisticsPage;
