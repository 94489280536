import React from "react";
import LinkPanel from "./LinkPanel";

const AboutUsHero = ({ mediaData }) => {
  return (
      <div className="
            mx-auto 
            max-w-7xl 
            px-6 py-8 
            sm:py-8 
      ">
                <h1>
        <span className="mt-1 block text-4xl font-bold tracking-tight sm:text-5xl xl:text-6xl">
          <span className="block text-gray-900">About</span>
          <div className='flex items-center'>
            <span className="block text-blue-600">LIFT METRICS</span>
          </div>
        </span>
              </h1>
        <div className="mx-auto 
        max-w-2xl 
        lg:grid 
        lg:max-w-none 
        lg:grid-cols-2 
        xl:grid-cols-1 
        xl:grid-rows-1 ">
          <div className="max-w-xl xl:col-end-1 xl:row-start-1">
            <p className="text-lg leading-8 text-black">
              <br />
              Take your training to the next level with Lift Metrics, providing comprehensive strength statistics to gauge your performance against competitors worldwide. 
              <br /><br />
              <strong>Why get involved?</strong>
              <br />Our platform empowers athletes and fitness enthusiasts by offering precise insights into their strength metrics, allowing them to benchmark their progress and optimise their training strategies. 
              <br />
              Whether you're a professional athlete seeking to dominate the field or a dedicated individual striving for personal excellence, our innovative tools provide the competitive edge you need to succeed. 
              <br />
              Join us and unleash your full potential today.<br />
              <br />
              <strong>How much does it cost?</strong>
              <br />
              Accessing the statistic comparison between you and other lifters is absolutely free of charge! 
              <br />
              However, for those looking to take their fitness journey to new heights - we offer premium services such as personalised online coaching and a range of top-quality affiliated products. 
              <br />
              The costs for these services vary depending on your needs and preferences. 
              <br />
              Get in touch with us today to explore our offerings and tailor a plan that fits your goals and budget seamlessly.
              </p>
          </div>
        </div>
      </div>
  );
};

export default AboutUsHero;


