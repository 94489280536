import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BackgroundBarbellImage from '../public/images/WWPLFinal4.png';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import styled from 'styled-components';

const BackgroundContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${BackgroundBarbellImage});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center items vertically */
  position: relative; /* Add position relative */
`;

const StartButton = styled.button`
  font-family: 'Bebas Neue', sans-serif;
  border: none;
  color: white;
  font-size: 30px;
  text-align: center;
  cursor: pointer;
  border-radius: 12px;
  padding: 10px 24px;
  background-color: #0d6efd; /* Add background color */
  margin-top: calc(10vh + 12vw);
`;

const HomePage = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    // Simulate API call or any other asynchronous task
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <BackgroundContainer>
    <Link to="/stats">
      <StartButton>GET STARTED NOW</StartButton>
    </Link>
  </BackgroundContainer>
)};

export default HomePage;
