import React, { useState } from 'react';
import TrainingGoals from './Components/TrainingGoals';
import UpdateInformationForm from './Components/UpdateInformationForm';

export default function UpdateProfilePage(userProfile) {
  const [forename, setForename] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');

  return (
    <div>
      <div className='flex justify-center gap-5'>
        <div className='col-span-1'>
      <UpdateInformationForm  userProfile={userProfile} setForename={setForename} setSurname={setSurname} setEmail={setEmail} forename={forename} surname={surname} email={email}/>
      </div>
      <TrainingGoals className='flex justify-center'/>
      </div>
    </div>
  );
}
