// Import Spinner component
import React, { useState } from 'react';
import updateInformationAPI from '../APIs/updateInformationAPI';
import Spinner from '../../Spinner';
import { useNavigate } from 'react-router-dom';

export default function UpdateInformationForm({ userProfile, setForename, setSurname, setEmail, forename, surname, email }) {

  const navigate = useNavigate();

  const [hasAgreed, setHasAgreed] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
    }
  

  const handleCheckboxChange = (e) => {
    setHasAgreed(e.target.checked);
  }

  const handleSubmit = async () => {
    setIsUpdating(true);
    try {
      const response = await updateInformationAPI(userProfile.userProfile.user_id, forename, surname, email);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
    await sleep(1000);
    setIsUpdating(false);
    window.location.reload();
  }

  return (
    <div className="overflow-hidden h-full bg-white shadow sm:rounded-lg">
      <div className="px-4 py-6 sm:px-6">
        <h3 className="mb-5 text-lg font-medium text-gray-900 dark:text-white">Update Your Information</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details</p>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Forename</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                className="border border-gray-300 rounded-md px-3 py-1 focus:outline-none focus:border-blue-500"
                value={userProfile.forename}
                onChange={(e) => setForename(e.target.value)}
              />
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Surname</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <input
                type="text"
                className="border border-gray-300 rounded-md px-3 py-1 focus:outline-none focus:border-blue-500"
                value={userProfile.surname}
                onChange={(e) => setSurname(e.target.value)}
              />
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Email address</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <input
                type="email"
                className="border border-gray-300 rounded-md px-3 py-1 focus:outline-none focus:border-blue-500"
                value={userProfile.email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </dd>
          </div>
        </dl>
      </div>
      <div className="flex items-center justify-center">
        <input
          id="link-checkbox"
          type="checkbox"
          value=""
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          onChange={handleCheckboxChange}
        />
        <label htmlFor="link-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          I agree with the <a href="#" className="text-blue-600 dark:text-blue-500 hover:underline">terms and conditions</a>.
        </label>
      </div>
      <div className="flex items-center justify-center mt-5">
        {/* Conditionally render Spinner if isUpdating is true */}
        {isUpdating ? (
          <Spinner />
        ) : (
          <button onClick={handleSubmit} disabled={!hasAgreed} className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full h-1/2 ${!hasAgreed ? 'opacity-50 cursor-not-allowed' : ''}`}>
            Update And Continue To Profile
          </button>
        )}
      </div>
    </div>
  );
}
