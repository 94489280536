import React from "react";

const AboutUsHeroMobile = ({ mediaData }) => {
  console.log("mediaData in AboutUsHeroMobile:", mediaData);

  return (
    <div>
      <div className="mx-auto max-w-7xl px-8">
        <div className="grid grid-cols-1">
          <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-black sm:text-6xl lg:col-span-1 xl:col-auto">
            About<br />
            <p className="text-blue-700">FIGHT-PICKS</p>
          </h1>
          <div className="max-w-xl">
            <p className="text-lg leading-8 text-black">
              <br />
              Where the heart-pounding excitement of MMA meets the thrill of betting without the real-life financial stakes. 
              If you've ever wanted to experience the rush of predicting fight outcomes, 
              strategizing your picks, and basking in the glory of victory, all without risking your hard-earned cash, then you're in the right place!
              <br />
              <br />
              <strong>Why get involved?</strong>
              <br />
              We've crafted a unique and engaging platform that brings together MMA enthusiasts and betting enthusiasts alike. 
              Our mission is to provide a space where passion for the sport meets the excitement of wagering, all in the spirit of fun and camaraderie. 
              We understand that not everyone is comfortable risking real money on bets, so we've created an environment where you can test your instincts and hone your betting skills without any financial consequences.
              <br />
              <br />
              <strong>How much does it cost?</strong>
              <br />
              Here at FIGHT-PICKS, there's no need to worry about financial risks - we provide a weekly 'Server Balance' budget for betting. 
              Our platform is designed purely for entertainment purposes, allowing you to focus on the excitement of making predictions, analyzing fighters, and competing against fellow fans. 
              It's all about the adrenaline rush, the thrill of the game, and the satisfaction of proving your MMA expertise.
            </p>
          </div>
          <div className="mt-6">
          <img
              src="path/to/your/image.jpg"
              alt="Description of the image"
              className="max-w-full h-auto"
              style={{ maxWidth: "50%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsHeroMobile;
