import React from 'react';
import { SocialIcon } from 'react-social-icons'

const FacebookLink = () => {
  const facebookUrl = 'https://www.facebook.com/people/United-Cornwall-FC/100089210023930/';}

export default function ContactUs() {
    return (
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
              <div>
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Get in touch</h2>
                <p className="mt-4 leading-7 text-gray-600">
                  If you have any questions or feedback we'd love to hear from you.
                </p>
              </div>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                <div className="rounded-2xl bg-blue-100 p-10">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">Email</h3>
                  <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                    <div>
                      <dt className="sr-only">Email</dt>
                      <dd>
                        <a className="font-semibold text-indigo-600" href="mailto:Jack@growcornwall.com">
                            Jack@growcornwall.com
                        </a>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="rounded-2xl bg-blue-100 p-10">
                <h3 className="text-base font-semibold leading-7 text-gray-900">Social Media</h3>
                <div className="flex">
                    <dt className="sr-only">Social Media</dt>
                    <dd className='mt-2 mr-2'> {/* Adjust the width and height accordingly */}
                        <SocialIcon url="https://www.facebook.com/people/United-Cornwall-FC/100089210023930/" />
                    </dd>
                    <dd className='mt-2 mr-2'> {/* Adjust the width and height accordingly */}
                        <SocialIcon url="https://twitter.com" />
                    </dd>
                    <dd className='mt-2 mr-2'> {/* Adjust the width and height accordingly */}
                        <SocialIcon url="https://instagram.com" />
                    </dd>
                    </div>

                </div>

                <div className="rounded-2xl bg-blue-100 p-10">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">Phone Number</h3>
                  <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                    <div>
                      <dt className="sr-only">Phone Number</dt>
                      <dd>
                        <a className="font-semibold text-indigo-600">
                        07460 118199
                        </a>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  