import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './NavBar/NavBar';
import HomePage from './HomePage/HomePage';
import AboutPage from './AboutPage/AboutPage';
import ContactUs from './Contact/Contact';
import StatisticsPage from './StatisticsPage/StatisticsPage';
import axios from 'axios';
import Spinner from './Spinner';
import ServicesBanner from './public/images/ServicesBanner.png';
import LoginPage from './LoginPage/LoginPage';
import ProfilePage from './ProfilePage/ProfilePage';
import {jwtDecode} from 'jwt-decode';

function App() {
  const [loginStatus, setLoginStatus] = useState(false);
  const [userProfile, setUserProfile] = useState({});

  		// Function to handle logout
	const handleLogout = () => {
		// Clear JWT token from browser storage
		localStorage.removeItem('jwtToken');
	
		// Remove Authorization header from axios
		delete axios.defaults.headers.common['Authorization'];
	
		// Perform any other necessary cleanup or redirection
    setLoginStatus(false);
    setUserProfile({});
	};

  const isAuthenticated = () => {
		const token = localStorage.getItem('jwtToken');
	
		if (token) {
		// Check token expiration
		const decoded = jwtDecode(token);
		const currentTime = Date.now() / 1000;
	
		if (decoded.exp < currentTime) {
			// Token expired
			handleLogout();
			return false;
		}
	
		// Set Authorization header for subsequent requests
		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
		return true;
		}
	
		return false;
	};

  // Middleware to check authentication before accessing protected routes
  const requireAuth = async (nextState, replace) => {
    if (!isAuthenticated()) {
      replace('/login'); // Redirect to login page if not authenticated
    }
  };

  return (
    <div className="App">
      <Router>
        <div>
        <NavBar loginStatus={loginStatus} handleLogout={handleLogout} />
        <Routes>
          <Route 
            path="/" 
            element={<HomePage />} />
          <Route 
            path="/login" 
            element={<LoginPage loginStatus={loginStatus} setLoginStatus={setLoginStatus} userProfile={userProfile} setUserProfile={setUserProfile} />} />
          <Route 
            path="/about" 
            element={<AboutPage />} />
          <Route 
            path="/stats" 
            element={<StatisticsPage />} />
          <Route 
            path="/contact" 
            element={<ContactUs />} />
          <Route
            path="/profile"
            element={<ProfilePage loginStatus={loginStatus} userProfile={userProfile} onEnter={requireAuth}/>}
          />
        </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
