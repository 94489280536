import React from 'react';

const UserFilters = ({ onChange }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange(name, value);
    };

    return (
        <div>
            <div className='grid grid-cols-1 gap-2 pt-5 text-white'>
            <select name="Sex" onChange={handleChange} className="border border-gray-400 bg-gray-700 rounded-md px-2 py-1 mr-2">
                <option value="">Select Gender</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
            </select>
            <select name="Equipment" onChange={handleChange} className="border border-gray-400 bg-gray-700 rounded-md px-2 py-1 mr-2">
                <option value="">Select Equipment</option>
                <option value="Raw">Raw</option>
                <option value="Wraps">Wraps</option>
                <option value="Multi-ply">Multi-ply</option>
                <option value="Single-ply">Single-ply</option>
                <option value="Unlimited">Unlimited</option>
                <option value="Straps">Straps</option>
            </select>
            <select name="AgeClass" onChange={handleChange} className="border border-gray-400 bg-gray-700 rounded-md px-2 py-1 mr-2">
                <option value="">Select Age Class</option>
                <option value="5-12">5-12</option>
                <option value="13-15">13-15</option>
                <option value="16-17">16-17</option>
                <option value="18-19">18-19</option>
                <option value="20-24">20-24</option>
                <option value="25-29">25-29</option>
                <option value="30-34">30-34</option>
                <option value="35-39">35-39</option>
                <option value="40-44">40-44</option>
                <option value="45-49">45-49</option>
                <option value="50-54">50-54</option>
                <option value="55-59">55-59</option>
                <option value="60-64">60-64</option>
                <option value="65-69">65-69</option>
                <option value="70-74">70-74</option>
                <option value="75-79">75-79</option>
                <option value="80-999">80+</option>
            </select>
            <select name="Event" onChange={handleChange} className="border border-gray-400 bg-gray-700 rounded-md px-2 py-1 mr-2">
                <option value="">Select Event</option>
                <option value="SBD">SBD</option>
                <option value="SD">SD</option>
                <option value="BD">BD</option>
                <option value="D">D</option>
                <option value="B">B</option>
                <option value="S">S</option>
                <option value="SB">SB</option>
            </select>
            <select name="WeightClassKg" onChange={handleChange} className="border border-gray-400 bg-gray-700 rounded-md px-2 py-1 mr-2">
            <option value="">Weight Class (Kg)</option>
            <option value="<20">&lt;20</option>
            <option value="20-24.9">20-24.9</option>
            <option value="25-29.9">25-29.9</option>
            <option value="30-34.9">30-34.9</option>
            <option value="35-39.9">35-39.9</option>
            <option value="40-44.9">40-44.9</option>
            <option value="45-49.9">45-49.9</option>
            <option value="50-54.9">50-54.9</option>
            <option value="55-59.9">55-59.9</option>
            <option value="60-64.9">60-64.9</option>
            <option value="65-69.9">65-69.9</option>
            <option value="70-74.9">70-74.9</option>
            <option value="75-79.9">75-79.9</option>
            <option value="80-84.9">80-84.9</option>
            <option value="85-89.9">85-89.9</option>
            <option value="90-94.9">90-94.9</option>
            <option value="95-99.9">95-99.9</option>
            <option value="100-104.9">100-104.9</option>
            <option value="105-109.9">105-109.9</option>
            <option value="110-114.9">110-114.9</option>
            <option value="115-119.9">115-119.9</option>
            <option value="120+">120+</option>
        </select>

        </div>
        </div>
    );
}



export default UserFilters;