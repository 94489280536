import React from "react";

const UserLifts = ({ onChange, lifts }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange(name, value);
    };

    return (
        <div className='grid grid-cols-1 gap-2 pt-5'>
            <div>
                <input
                    name="Best3SquatKg"
                    placeholder="Max Squat (Kg)"
                    onChange={handleChange}
                    className="border border-gray-400 bg-gray-700 text-white rounded-md px-2 py-1 mr-2"
                />
            </div>
            <div>
                <input
                    name="Best3BenchKg"
                    placeholder="Max Bench (Kg)"
                    onChange={handleChange}
                    className="border border-gray-400 bg-gray-700 text-white rounded-md px-2 py-1 mr-2"
                />
            </div>
            <div>
                <input
                    name="Best3DeadliftKg"
                    placeholder="Max Deadlift (Kg)"
                    onChange={handleChange}
                    className="border border-gray-400 bg-gray-700 text-white rounded-md px-2 py-1 mr-2"
                />
            </div>
        </div>
    );
}

export default UserLifts;
