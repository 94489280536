import React, { useState, useEffect } from "react";
import AboutUsHero from "./Components/AboutUsHero";
import AboutUsHeroMobile from "./Components/AboutUsHeroMobile"; // Import the mobile version component
import OurValuesSection from "./Components/OurValuesSection";
import Spinner from "../Spinner";
import axios from "axios";
import LinkPanel from "./Components/LinkPanel";

export default function AboutUs() {
  const wordpressURL = "http://wordpress.local";
  const tagSlug = "about-us";
  const [mediaData, setMediaData] = useState([]);
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    setLoading(true);

    axios
      .get(`${wordpressURL}/wp-json/wp/v2/media?custom_tags=${tagSlug}`)
      .then((response) => {
        // Check if the 'custom_tags' field is present in the response
        const updatedMediaData = response.data[0]

        setMediaData(updatedMediaData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Determine whether to use the mobile version based on viewport width
  const isMobile = window.innerWidth <= 768; // You can adjust this threshold

  return (
    <div className="relative isolate bg-white -z-10 overflow-hidden lg:bg-gradient-to-b lg:from-blue-200/100 pt-8">
      {loading ? (
        <div className='min-h-screen flex items-center justify-center'>
          <Spinner />
        </div>
      ) : (
        <>
          <div className='mb-10'>
            {/* Conditionally render the appropriate component based on viewport width */}
            {isMobile ? (
              <AboutUsHeroMobile mediaData={mediaData} />
            ) : (
              <div className="relative isolate -z-10 overflow-hidden bg-gradient-to-b from-blue-200/100 pt-8">
              <div className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-blue-600/10 ring-1 ring-blue-50 sm:-mr-80 lg:-mr-96" />        
              <div className='grid grid-cols-2 items-center'>
                <div className='col-span-1 flex justify-center'>
              <AboutUsHero className='grid col-span-2' mediaData={mediaData} />
              </div>
              <div className='col-span-1 pr-10'>
              <LinkPanel />
              </div>
              </div>
              </div>
            
            )}
          </div>
          {/* Other components like OurValuesSection can be rendered here */}
        </>
      )}
    </div>
  );
}
