import React, { useState } from 'react';
import axios from 'axios';
import bcrypt from 'bcryptjs';
import LoginSuccessPopUp from './LoginSuccessPopUp';
import Spinner from '../Spinner';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';




const LoginPage = ({ isLoggedIn, setLoginStatus, setUserProfile }) => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');

  function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
  }

// Function to handle login
	const handleLogin = async (event) => {
		event.preventDefault();
		setIsLoggingIn(true);
		setError('');
		setSuccessMessage('');
	
		try {
		// Send the login request to the Flask backend
		const response = await axios.post('http://localhost:5000/api/auth-user', { username, password });
	
		// Retrieve the JWT from the response and store it securely (e.g., in browser storage)
		const token = response.data.token;
		localStorage.setItem('jwtToken', token);
	
		// Extract user profile from JWT
		const userProfile = jwtDecode(token);
	
		// Make subsequent API requests with the JWT
		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	
		// Perform desired actions after successful login (e.g., redirect, display success message)
		setSuccessMessage('Successfully logged in...redirecting to Profile');
		await sleep(1000);
		console.log('Login successful!');
		setIsLoggingIn(false);
		setLoginStatus(true);
		setUserProfile(userProfile);
		navigate('/profile');
		
		} catch (error) {
		// Handle login error (e.g., display error message)
		console.error(error);
		setError('Invalid username or password.');
		setIsLoggingIn(false);
		}
	};

		// Function to handle logout
	const handleLogout = () => {
		// Clear JWT token from browser storage
		localStorage.removeItem('jwtToken');
	
		// Remove Authorization header from axios
		delete axios.defaults.headers.common['Authorization'];
	
		// Perform any other necessary cleanup or redirection
	};
	
	// Function to check if user is authenticated
	const isAuthenticated = () => {
		const token = localStorage.getItem('jwtToken');
	
		if (token) {
		// Check token expiration
		const decoded = jwtDecode(token);
		const currentTime = Date.now() / 1000;
	
		if (decoded.exp < currentTime) {
			// Token expired
			handleLogout();
			return false;
		}
	
		// Set Authorization header for subsequent requests
		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
		return true;
		}
	
		return false;
	};

	const handleRegister = async (event) => {
    event.preventDefault()
		setIsLoggingIn(true);
		setError('');
		setSuccessMessage('');
	
		if (!username || !password) {
			setError('Please provide both username and password.');
			return;
		}
	
		try {
			// Send the registration request to the Flask backend
			const response = await axios.post('http://localhost:5000/api/register', { username, password });
	
			// Retrieve the JWT from the response and store it securely (e.g., in memory)
			const token = response.data.token;
			const userProfile = response.data.user_profile
			console.log('logging userProfile in handleRegister');
			console.log(userProfile);

			if (typeof token !== 'undefined') {
				// Make subsequent API requests with the JWT
				axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
		
				// Perform desired actions after successful registration (e.g., redirect, display success message)
				setSuccessMessage('Successfully registered...redirecting to Profile');
				await sleep(1000);
				setIsLoggingIn(false);
				setLoginStatus(true);
				setUserProfile(userProfile)
				// Redirect to ProfilePage
				navigate('/profile');
			}
			else {

				const message = response.data.message;
				setError(message);
				setIsLoggingIn(false);

			}
	

		} catch (error) {
			// Handle registration error (e.g., display error message)
			console.error(error);
			setError('Failed to register user.');
			setIsLoggingIn(false);
		}
	};


	return (
		<section className="bg-gray-50 dark:bg-gray-900 pt-12">
			<div className="flex flex-col items-center pt-12 mx-auto md:h-screen lg:py-0">
				<a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
					Lift Metrics
				</a>
				<div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
					<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
						<h1 className="text-xl text-center pb-3 font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
							Sign in to your account
						</h1>
						{/* Display error message */}
						{error && <p className="text-red-500 text-sm text-center">{error}</p>}
						{successMessage && 
						<p className="text-green-500 text-sm text-center">{successMessage}</p>
						}
						<form className="space-y-4 md:space-y-6" action="#">
							<div>
								<label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
									Your Username
								</label>
								<input
									type="email"
									name="email"
									id="email"
									className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
									placeholder="name@gmail.com"
									required
									value={username} 
									onChange={(e) => setUsername(e.target.value)}
								/>
							</div>
							<div>
								<label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
									Password
								</label>
								<input
									type="password"
									name="password"
									id="password"
									placeholder="••••••••"
									className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
									required
									value={password} 
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
						{isLoggingIn ? (
							<div className='flex items-center justify-center'>
							<Spinner />
							</div>
							) : (
								<button
								type="button"
								className="w-full text-black bg-green-300 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
								onClick={handleLogin}
							>
								Login to Lift Metrics
							</button>
							)}
							<div className="flex items-center justify-between">
								<div className="flex items-start">
									<div className="flex items-center h-5">
										<input
											id="remember"
											aria-describedby="remember"
											type="checkbox"
											className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
											required
										/>
									</div>
									<div className="ml-3 text-sm">
										<label htmlFor="remember" className="text-gray-500 dark:text-gray-300">
											Remember me
										</label>
									</div>
								</div>
								<p className="text-sm font-light text-gray-500 dark:text-gray-400">
								Don’t have an account yet?{' '}
								<a
									href="#"
									onClick={handleRegister}
									className="font-medium text-primary-600 hover:underline dark:text-primary-500"
								>
									Sign up
								</a>
							</p>

							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
}


export default LoginPage;