import React from 'react';
import strengthSvg from '../../public/images/strength.svg';
import cardioSvg from '../../public/images/cardio.svg';
import dietSvg from '../../public/images/diet.svg';

function TrainingGoals() {
  return (
    <div>
      <h3 className="mt-5 mb-5 text-lg font-medium text-gray-900 dark:text-white">Content Preferences</h3>
      <ul className="grid w-full gap-6 md:grid-cols-1">
        <li>
          <input type="checkbox" id="react-option" value="" className="hidden peer" required />
          <label htmlFor="react-option" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">                           
            <div className="block">
            <img src={strengthSvg} alt="strength" className="w-10 h-10" />
            <div className="w-full text-lg font-semibold">Strength Training</div>
              <div className="w-full text-sm">Looking to hit your PBs?</div>
            </div>
          </label>
        </li>
        <li>
          <input type="checkbox" id="flowbite-option" value="" className="hidden peer" />
          <label htmlFor="flowbite-option" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
            <div className="block">
                <img src={cardioSvg} alt="strength" className="w-10 h-10" />
              <div className="w-full text-lg font-semibold">Fat Loss</div>
              <div className="w-full text-sm">Do you want to start a fat loss journey?</div>
            </div>
          </label>
        </li>
        <li>
          <input type="checkbox" id="angular-option" value="" className="hidden peer" />
          <label htmlFor="angular-option" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
            <div className="block">
            <img src={dietSvg} alt="strength" className="w-10 h-10" />
              <div className="w-full text-lg font-semibold">Personalised Diet</div>
              <div className="w-full text-sm">Are you looking to put together a personalised diet?</div>
            </div>
          </label>
        </li>
      </ul>
    </div>
  );
}

export default TrainingGoals;
