import axios from 'axios';

const updateInformationAPI = async (userId, forename, surname, email) => {
  try {
    const response = await axios.post('http://localhost:5000/api/update-info', {
        userId,
        forename,
        surname,
        email,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to update information');
  }
};

export default updateInformationAPI;
