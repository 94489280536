import axios from 'axios';

let BASE_URL;

if (process.env.NODE_ENV === 'production') {
  // Set production base URL
  BASE_URL = process.env.PROD_BASE_URL || 'http://api.worldwidepowerlifting.com';
  // Set development base URL
}
else {
  BASE_URL = process.env.DEV_BASE_URL || 'http://localhost:5000';
}
const api = axios.create({
  baseURL: BASE_URL,
});

const calculateStatistics = async (filters, lifts) => {
  try {
    const response = await api.post('/calculate', { filters, lifts });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error submitting data:', error);
    throw error;  
  }
};

export default calculateStatistics;
