import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import './NavBar.css'; // Import CSS file with styles
import NavBarLogo from '../public/images/logotext.png';

export default function NavBar({ loginStatus, handleLogout }) {
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="flex items-center">
        <img src={NavBarLogo} style={{ width: '70px', height: 'auto' }} />
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <Link to="/" className={`text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-bold ${location.pathname === '/' ? 'bg-gray-700 text-white' : ''}`}>
                  Home
                </Link>
                <Link to="/stats" className={`text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-bold ${location.pathname === '/pickem' ? 'bg-gray-700 text-white' : ''}`}>
                  Calculator
                </Link>
              </div>
      </div>
      </div>
      </div>
    </nav>
  );
}
