import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Spinner from '../Spinner';
import UpdateProfilePage from '../UpdateProfileInfoPage/UpdateProfileInfoPage';

function ProfilePage({ isLoggedIn, userProfile }) {
    console.log('logging userprofile')
    console.log(userProfile);

  return (
    <div>
      <div>
      {userProfile.forename === null ? <UpdateProfilePage userProfile={userProfile} /> : null}
    </div>
    </div>
  );
}

export default ProfilePage;
