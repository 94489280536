import React from 'react';
import GaugeComponent from 'react-gauge-component';

const GaugeComponents = ({ chartData }) => {
  console.log('chartData:', chartData);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      {/* Squat Gauge */}
      <div>
        <GaugeComponent
          labels={{
            valueLabel: {
              style: {
                fontSize: '36px',
                fill: '#ffffff', // Set text color to white
                maxDecimalDigits: 0,
                textShadow: 'none',
                fontWeight: 'bold'
              }
            },
            tickLabels: {
              type: 'outer',
              defaultTickValueConfig: {
                style: {
                  fontSize: '14px',
                  fill: '#ffffff' // Set tick color to white
                }
              }
            }
          }}
          arc={{
            subArcs: [
              { limit: 20, color: '#EA4228', showTick: true },
              { limit: 40, color: '#F58B19', showTick: true },
              { limit: 60, color: '#F5CD19', showTick: true },
              { limit: 80, color: '#5BE12C', showTick: true },
              { limit: 100, color: '#006aff', showTick: true }
            ]
          }}
          value={chartData.Best3SquatKg}
        />
        <h2 className='text-center mb-2 text-2xl font-bold text-white'>Squat</h2>
      </div>

      {/* Bench Press Gauge */}
      <div>
        <GaugeComponent
          labels={{
            valueLabel: {
              style: {
                fontSize: '36px',
                fill: '#ffffff', // Set text color to white
                maxDecimalDigits: 0,
                textShadow: 'none',
                fontWeight: 'bold'
              }
            },
            tickLabels: {
              type: 'outer',
              defaultTickValueConfig: {
                style: {
                  fontSize: '14px',
                  fill: '#ffffff' // Set tick color to white
                }
              }
            }
          }}
          arc={{
            subArcs: [
              { limit: 20, color: '#EA4228', showTick: true },
              { limit: 40, color: '#F58B19', showTick: true },
              { limit: 60, color: '#F5CD19', showTick: true },
              { limit: 80, color: '#5BE12C', showTick: true },
              { limit: 100, color: '#006aff', showTick: true }
            ]
          }}
          value={chartData.Best3BenchKg}
        />
        <h2 className='text-center mb-2 text-2xl font-bold text-white'>Bench Press</h2>
      </div>

      {/* Deadlift Gauge */}
      <div>
        <GaugeComponent
          labels={{
            valueLabel: {
              style: {
                fontSize: '36px',
                fill: '#ffffff', // Set text color to white
                maxDecimalDigits: 0,
                textShadow: 'none',
                fontWeight: 'bold'
              }
            },
            tickLabels: {
              type: 'outer',
              defaultTickValueConfig: {
                style: {
                  fontSize: '14px',
                  fill: '#ffffff' // Set tick color to white
                }
              }
            }
          }}
          arc={{
            subArcs: [
              { limit: 20, color: '#EA4228', showTick: true },
              { limit: 40, color: '#F58B19', showTick: true },
              { limit: 60, color: '#F5CD19', showTick: true },
              { limit: 80, color: '#5BE12C', showTick: true },
              { limit: 100, color: '#006aff', showTick: true }
            ]
          }}
          value={chartData.Best3DeadliftKg}
        />
        <h2 className='text-center mb-2 text-2xl font-bold text-white'>Deadlift</h2>
      </div>
    </div>
  );
};

export default GaugeComponents;
